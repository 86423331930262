import React, { useState, useContext, useEffect } from "react"
import FocusTrap from "focus-trap-react"
import { LanguageContext } from "../hooks/use-language"

const MaintenanceMode = (props) => {
  const pw = "fdi"
  const [isActive, setActive] = useState(props.isActive)
  const [error, setError] = useState(false)
  const [value, setValue] = useState("")
  const { language } = useContext(LanguageContext)

  const handleClick = (value) => {
    if (value === pw) {
      setActive(false)
    } else {
      setError(true)
    }
  }

  useEffect(() => {
    if (isActive === "true") {
      document.body.classList.add("maintenance")
    } else {
      document.body.classList.remove("maintenance")
    }
  }, [isActive])

  return (
    <div className={isActive === "true" ? "maintenance-mode is-active" : "maintenance-mode"}>
      <div className="text">
        <h2>
          Sivustomme avautuu pian. Tervetuloa takaisin!
          <br /> We're working on our website. Welcome back soon!
        </h2>
        <p>
          Ota yhteyttä, Contact us:
          <br />
          hello@finnishdesigninfo.fi
        </p>
      </div>
      <div className="maintenance-mode__input-wrap">
        <FocusTrap active={isActive === "true" ? true : false}>
          <form onSubmit={(e) => e.preventDefault()}>
            <label htmlFor="pw">{language === "fi" ? "Syötä salasana" : "Enter password"}</label>
            <input
              id="pw"
              className="password"
              type="password"
              value={value}
              onChange={(e) => setValue(e.target.value)}
              placeholder={language === "fi" ? "Salasana" : "Password"}
            />
            <input
              type="submit"
              onClick={() => handleClick(value)}
              className="button"
              value={language === "fi" ? "Kirjaudu" : "Sign in"}
            />
          </form>
        </FocusTrap>
      </div>
      {error && <div className="error-message">{language === "fi" ? "Väärä salasana" : "Wrong password"}</div>}
    </div>
  )
}

export default MaintenanceMode
