export const getFirstSentence = (text) => {
  const endIndex = text.search(/[.!?]/)
  const first = text.slice(0, endIndex + 1)
  const rest = text.slice(endIndex + 1)
  return { first, rest }
}

export const addPeriodToEndOfSentence = (sentence) => {
  if (
    sentence.charAt(sentence.length - 1) !== "." &&
    sentence.charAt(sentence.length - 1) !== "!" &&
    sentence.charAt(sentence.length - 1) !== "?"
  ) {
    sentence += "."
  }
  return sentence
}

export const getTextColorForBackground = (backgroundColor) => {
  // Convert hexadecimal color to RGB
  if (backgroundColor.startsWith("#")) {
    backgroundColor = hexToRgb(backgroundColor)
  }

  // Extract RGB components
  let r, g, b
  if (backgroundColor.startsWith("rgb")) {
    ;[r, g, b] = backgroundColor
      .replace(/rgba?\(|\s+|\)/g, "")
      .split(",")
      .map(Number)
  }

  // Calculate relative luminance
  const luminance = (0.2126 * r + 0.7152 * g + 0.0722 * b) / 255

  // Determine if background is "light" or "dark" based on relative luminance
  if (luminance > 0.5) {
    return "#000000" // black text on light background
  } else {
    return "#FFFFFF" // white text on dark background
  }
}

// Helper function to convert hexadecimal color to RGB
export const hexToRgb = (hexColor) => {
  const hex = hexColor.replace("#", "")
  const r = parseInt(hex.substring(0, 2), 16)
  const g = parseInt(hex.substring(2, 4), 16)
  const b = parseInt(hex.substring(4, 6), 16)
  return `rgb(${r}, ${g}, ${b})`
}

export const handleScroll = (d) => {
  if (d === "up") {
    document.documentElement.scrollTo({
      top: 0,
      behavior: "smooth",
    })
  } else {
    document.documentElement.scrollTo({
      top: document.body.scrollHeight,
      behavior: "smooth",
    })
  }
}
