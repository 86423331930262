import { useContext } from "react"
import { CurrentPageContext } from "../components/CurrentPageContext"

export const useSubscribe = () => {
  const { newsletterActive, setNewsletterActive } = useContext(CurrentPageContext)

  const handleNewsLetter = () => {
    setNewsletterActive(!newsletterActive)
    if (document.location.hash === "#subscribe" || document.location.hash === "#subscribe-en") {
      document.location.hash = ""
    } else {
      document.location.hash = "subscribe"
    }
  }
  return { handleNewsLetter, newsletterActive, setNewsletterActive }
}
