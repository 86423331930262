import React, { useContext } from "react"
import { Link } from "react-router-dom"
import { Date as PrismicDate, RichText } from "prismic-reactjs"
import ReadMore from "./ReadMore"
import ProgressiveImage from "react-progressive-image-loading"
import { LanguageContext } from "../hooks/use-language"

const ArchiveItem = (props) => {
  const { language } = useContext(LanguageContext)

  const timestamp = PrismicDate(props.item.first_publication_date)
  const formattedTimestamp = Intl.DateTimeFormat("fi", {
    year: "numeric",
    month: "numeric",
    day: "numeric",
  }).format(timestamp)

  if (props.item.data) {
    let url = ""
    if (props.item.type === "news") {
      url = "/stories"
    }

    return (
      <div className="masonry-grid__item" role="article">
        {props.item?.data?.featured_image && (
          <Link onClick={props.handleNavClose} to={`${url}/${props.item?.uid}?lang=${language}`} aria-hidden="true">
            <ProgressiveImage
              preview={props.item?.data?.featured_image?.preview?.url}
              src={props.item?.data?.featured_image?.url}
              render={(src, style) => (
                <img
                  src={src}
                  style={style}
                  alt={
                    props.item?.data?.featured_image?.alt ??
                    `${language === "fi" ? "Kuva " : "Image"}` + RichText.asText(props.item?.data?.title)
                  }
                />
              )}
            />
          </Link>
        )}

        {props.item.data.title && (
          <Link
            aria-label={language === "fi" ? "Tarinan otsikko" : "Article title"}
            onClick={props.handleNavClose}
            to={`${url}/${props.item.uid}?lang=${language}`}
          >
            <h2 className="title">{RichText.asText(props.item?.data?.title)}</h2>
          </Link>
        )}

        <div className="item__meta">
          {props.item?.tags && (
            <ul className="tags">
              {props.item.tags.map((tagTerm, i) => (
                <li key={i} className="tag">
                  <Link
                    aria-label={language === "fi" ? `Arkisto avainsanalle ${tagTerm}` : `Archive for tag ${tagTerm}`}
                    onClick={props.handleNavClose}
                    to={`${url}-tag/${tagTerm}/page/1?lang=${language}`}
                  >
                    {tagTerm}
                  </Link>
                </li>
              ))}
            </ul>
          )}
          {props.item.type === "news" && (
            <p
              aria-label={language === "fi" ? `Julkaistu: ${formattedTimestamp}` : `Published: ${formattedTimestamp}`}
              className="updated"
            >
              {formattedTimestamp}
            </p>
          )}
        </div>

        {props.item?.data?.excerpt && <p className="excerpt">{props.item?.data?.excerpt[0]?.text}</p>}
        <ReadMore
          label={props.item?.data?.title[0]?.text}
          handleNavClose={props.handleNavClose}
          url={`${url}/${props.item?.uid}${language !== "fi" ? `?lang=${language}` : ""}`}
        />
      </div>
    )
  } else return null
}

export default ArchiveItem
