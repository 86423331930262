import React, { useContext } from "react"
import { LanguageContext } from "../hooks/use-language"

const AnalyticsCard = ({ title, figure, index }) => {
  const { language } = useContext(LanguageContext)
  const indexClass = (() => {
    switch (index) {
      case 0:
        return "first"
      case 1:
        return "middle"
      case 2:
        return "last"
      default:
        return ""
    }
  })()

  return (
    <div
      className={`analytics_card ${indexClass}`}
      aria-label={language === "fi" ? "Tilasto, osa " + index + 1 : "Statistics Card " + index + 1}
      role="contentinfo"
    >
      {title ? <p className="analytics_text">{title}</p> : null}
      {figure ? <h3 className={"analytics_figure"}>{figure}</h3> : null}
    </div>
  )
}

export default AnalyticsCard
