import React, { useContext } from "react"
import { LanguageContext } from "../hooks/use-language"

const Language = ({ id }) => {
  const { language, switchLanguage } = useContext(LanguageContext)
  const ariaLabel = language === "fi" ? "Suomeksi" : "English"
  return (
    <button
      onClick={() => switchLanguage(language === "fi" ? "en-US" : "fi")}
      disabled={id === language}
      className={`language-switcher__button ${language.toLowerCase().includes(id.toLowerCase()) ? "disabled" : ""}`}
      aria-label={ariaLabel}
    >
      {id.toUpperCase()}
    </button>
  )
}

export default Language
