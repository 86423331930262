import React, { useContext } from "react"
import { TransitionGroup, CSSTransition } from "react-transition-group"
import { Helmet } from "react-helmet-async"

import GridWrap from "./GridWrap"
import ArchiveItem from "./ArchiveItem"
import Pagination from "./Pagination"
import Subscribe from "./Subscribe"
import ArchiveHero from "./ArchiveHero"
import { useArchivePage } from "../hooks/use-archive-page"
import { LanguageContext } from "../hooks/use-language"

const ArchiveNews = ({ match }) => {
  const { language } = useContext(LanguageContext)
  const {
    handlePageChange,
    handleFilterStories,
    theme,
    heroStories,
    subscribeDoc,
    search,
    setSearch,
    documents,
    currentPage,
    tag,
    pages,
    doc,
    finalDocuments,
    finalNumberOfPages,
  } = useArchivePage(match)

  return (
    <>
      <Helmet>
        {tag === "" ? (
          <title>
            {doc.title !== ""
              ? `${doc.title} - ${
                  language === "fi" ? `Sivu ${currentPage}` : `Page ${currentPage}`
                } - Finnish Design Info`
              : ""}
          </title>
        ) : (
          <title>
            {doc.title !== ""
              ? `${doc.title} - ${language === "fi" ? `Avainsana ${tag}` : `Tag ${tag}`} - ${
                  language === "fi" ? `Sivu ${currentPage}` : `Page ${currentPage}`
                } - Finnish Design Info`
              : ""}
          </title>
        )}
        {doc.meta_description !== "" && <meta name="description" content={doc.meta_description} />}
      </Helmet>

      <TransitionGroup component={null}>
        {doc.uid && (
          <div className="archive">
            <CSSTransition timeout={300} classNames="fade">
              <>
                <ArchiveHero
                  highlightFirstSentence={true}
                  title={heroStories?.title ?? ""}
                  buttons={heroStories?.buttons ?? ""}
                  bgcolor={heroStories?.bgcolor ?? ""}
                  text={heroStories?.text ?? ""}
                  onClick={handleFilterStories}
                  onInputChange={(e) => setSearch(e.target.value)}
                  inputValue={search}
                  theme={theme}
                />
                <div className="container-fluid">
                  <GridWrap>
                    {finalDocuments.length > 0
                      ? finalDocuments.map((item) => <ArchiveItem key={item.uid} item={item} />)
                      : null}
                  </GridWrap>
                </div>
                {documents.length > 0 && (
                  <Pagination
                    slug={tag ? `stories-tag/${tag}` : "stories"}
                    pages={pages}
                    handlePageChange={handlePageChange}
                    totalPages={finalNumberOfPages}
                    currentPage={currentPage}
                  />
                )}
                {subscribeDoc ? (
                  <Subscribe title={subscribeDoc.title[0].text} buttonText={subscribeDoc.button_label[0].text} />
                ) : null}
              </>
            </CSSTransition>
          </div>
        )}
      </TransitionGroup>
    </>
  )
}

export default ArchiveNews
