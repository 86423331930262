import React, { useState, useEffect, useContext, useCallback } from "react"
import Prismic from "prismic-javascript"
import client from "../prismic-configuration"
import { TransitionGroup, CSSTransition } from "react-transition-group"
import Header from "./Header"
import { Date as PrismicDate } from "prismic-reactjs"
import { Link } from "react-router-dom"
import ReadMore from "./ReadMore"
import DatePicker, { registerLocale } from "react-datepicker"
import "react-datepicker/dist/react-datepicker.css"
import ProgressiveImage from "react-progressive-image-loading"
import enUS from "date-fns/locale/en-US"
import fi from "date-fns/locale/fi"
import moment from "moment"
import { RichText } from "prismic-reactjs"
import customLink from "../customLink"
import { Helmet } from "react-helmet-async"
import { LanguageContext } from "../hooks/use-language"
registerLocale("en-US", enUS)
registerLocale("fi", fi)

const ArchiveEvent = ({ match }) => {
  const [doc, setDoc] = useState({
    title: "",
    uid: "",
    meta_description: "",
    hero_image: {},
  })
  const [documents, setDocuments] = useState([])
  const [tags, setTags] = useState([])
  const [availableTags, setAvailableTags] = useState([])
  const { language } = useContext(LanguageContext)
  const [startDate, setStartDate] = useState(null)
  const [endDate, setEndDate] = useState(null)
  const [eventDates, setEventDates] = useState([])
  const [eventCount, setEventCount] = useState(9)

  const getDocument = useCallback(async () => {
    const res = await client.getSingle("events", { lang: language })

    if (res) {
      setDoc({
        title: res.data.title[0]?.text,
        uid: res.uid,
        meta_description: res.data.meta_description[0]?.text,
        hero_image: res.data.hero_image,
      })
    }
  }, [language])

  const getTags = useCallback(async () => {
    const today = new Date()
    const yesterday = new Date(today)
    yesterday.setDate(yesterday.getDate() - 1)

    const res = await client.query(
      [
        Prismic.Predicates.at("document.type", "event"),
        Prismic.Predicates.dateAfter("my.event.ending_date", yesterday),
      ],
      { lang: language, pageSize: 100, page: 1, orderings: "[my.event.starting_date]" }
    )

    if (res) {
      let tags = res.results.map((item) => {
        return item.tags
      })
      tags = [...new Set(tags.flat())]
      setAvailableTags(tags)
    }
  }, [language])

  useEffect(() => {
    getDocument()
    getTags()
  }, [getDocument, getTags, language])

  const getDocuments = useCallback(async () => {
    const today = new Date()
    const yesterday = new Date(today)
    yesterday.setDate(yesterday.getDate() - 1)

    const response = await client.query(
      [
        tags ? Prismic.Predicates.any("document.tags", tags) : Prismic.Predicates.at("document.type", "event"),
        startDate && !endDate
          ? Prismic.Predicates.dateAfter("my.event.ending_date", startDate)
          : Prismic.Predicates.dateAfter("my.event.ending_date", yesterday),
      ],
      { lang: language, pageSize: 9, page: 1, orderings: "[my.event.starting_date]" }
    )

    if (response) {
      let events = response.results
      if (endDate) {
        events = response.results.filter((item) => {
          if (eventDates.includes(item.data.starting_date) || eventDates.includes(item.data.ending_date)) {
            return item
          } else return null
        })
        setDocuments(events)
      } else {
        setDocuments(events)
      }
    }
  }, [endDate, startDate, language, tags, eventDates])

  useEffect(() => {
    getDocuments()
  }, [tags, language, startDate, endDate, getDocuments])

  const handleDateChange = (start, end) => {
    setEventCount(9)
    setStartDate(start)
    setEndDate(end)

    let dates = []
    if (start && end) {
      const theDate = new Date(start)
      while (theDate < end) {
        dates = [...dates, moment(theDate).format("YYYY-MM-DD")]
        theDate.setDate(theDate.getDate() + 1)
      }
      dates = [...dates, moment(end).format("YYYY-MM-DD")]
      setEventDates(dates)
    } else if (!start && end) {
      const theDate = new Date()
      while (theDate < end) {
        dates = [...dates, moment(theDate).format("YYYY-MM-DD")]
        theDate.setDate(theDate.getDate() + 1)
      }
      dates = [...dates, moment(end).format("YYYY-MM-DD")]
      setEventDates(dates)
    } else {
      setEventDates([])
    }
  }

  const handleTagChange = (tagTerm) => {
    setEventCount(9)
    setTags([tagTerm])
    document.getElementById("filters").scrollIntoView({
      behavior: "smooth",
    })
  }

  const handleTagsChange = (tagTerm) => {
    setEventCount(9)
    let newTags = [...tags]
    if (newTags.includes(tagTerm)) {
      newTags.splice(newTags.indexOf(tagTerm), 1)
      setTags(newTags)
    } else {
      newTags.push(tagTerm)
      setTags(newTags)
    }
  }

  return (
    <>
      <Helmet>
        <title>{doc.title !== "" ? `${doc.title} - Finnish Design Info` : ""}</title>
        {doc.meta_description !== "" && <meta name="description" content={doc.meta_description} />}
      </Helmet>
      <TransitionGroup component={null}>
        {doc.uid && (
          <CSSTransition timeout={300} classNames="fade">
            <div className="archive">
              <Header text={doc.title} />
              {doc.hero_image?.url && (
                <ProgressiveImage
                  preview={doc.hero_image?.preview?.url}
                  src={doc.hero_image?.url}
                  render={(src, style) => (
                    <img className="archive__image" src={src} style={style} alt={`${doc.hero_image?.alt}`} />
                  )}
                />
              )}
              <div className="container">
                <div id="filters" className="event-filters">
                  <div className="event-filters__dates">
                    <h3>{language === "fi" ? "Näytä tapahtumat välillä" : "Show events between dates"}</h3>
                    <div className="date-start">
                      <label htmlFor="start">
                        {language === "fi" ? "Näytä tapahtumat alkaen päivämäärästä" : "Show events starting from date"}
                      </label>
                      <DatePicker
                        id="end"
                        autoComplete="off"
                        locale={language === "fi" ? "fi" : "en-US"}
                        dateFormat="d.M.Y"
                        selected={startDate}
                        onChange={(start) => handleDateChange(start, endDate)}
                        minDate={new Date()}
                        startDate={startDate}
                        endDate={endDate}
                        placeholderText={language === "fi" ? "Valitse pvm" : "Select date"}
                        strictParsing={true}
                      />
                      <button
                        className={startDate ? "date-clear is-active" : "date-clear"}
                        onClick={() => setStartDate(null)}
                        aria-label={language === "fi" ? "Tyhjennä" : "Clear"}
                      >
                        <ion-icon name="close-sharp" style={{ fontSize: "20px" }}></ion-icon>
                      </button>
                    </div>
                    <div className="divider">
                      <ion-icon name="arrow-forward-sharp" style={{ fontSize: "20px" }}></ion-icon>
                    </div>
                    <div className="date-end">
                      <label htmlFor="end">
                        {language === "fi" ? "Näytä tapahtumat päättyen päivämäärään" : "Show events before date"}
                      </label>
                      <DatePicker
                        id="end"
                        autoComplete="off"
                        locale={language === "fi" ? "fi" : "en-US"}
                        dateFormat="d.M.Y"
                        selected={endDate}
                        onChange={(end) => handleDateChange(startDate, end)}
                        disablePast={true}
                        startDate={startDate}
                        endDate={endDate}
                        minDate={startDate ? startDate : new Date()}
                        placeholderText={language === "fi" ? "Valitse pvm" : "Select date"}
                        strictParsing={true}
                      />
                      <button
                        className={endDate ? "date-clear is-active" : "date-clear"}
                        onClick={() => setEndDate(null)}
                        aria-label={language === "fi" ? "Tyhjennä" : "Clear"}
                      >
                        <ion-icon name="close-sharp" style={{ fontSize: "20px" }}></ion-icon>
                      </button>
                    </div>
                  </div>
                  <div className="event-filters__tags">
                    <h3 id="tag-message">
                      {language === "fi" ? "Näytä tapahtumat avainsanoilla" : "Show events with tags"}
                    </h3>
                    <ul aria-labelledby="tag-message">
                      {availableTags.map((tagTerm, i) => (
                        <li key={i}>
                          <label className={tags.includes(tagTerm) ? "is-active" : ""} htmlFor={`tag${i}`}>
                            {tagTerm}
                          </label>
                          <input
                            value={tagTerm}
                            id={`tag${i}`}
                            name={`tag${i}`}
                            checked={tags.includes(tagTerm) ? true : false}
                            type="checkbox"
                            onChange={() => handleTagsChange(tagTerm)}
                          ></input>
                        </li>
                      ))}
                    </ul>
                  </div>
                </div>
                <div className="events-grid">
                  {documents.length > 0 ? (
                    documents
                      .filter((item, i) => {
                        if (i < eventCount) return item
                        else return null
                      })
                      .map((item, i) => {
                        const startingTimestamp = PrismicDate(item.data.starting_date)
                        const formattedStartingTimestamp = Intl.DateTimeFormat("fi", {
                          year: "numeric",
                          month: "numeric",
                          day: "numeric",
                        }).format(startingTimestamp)
                        const endingTimestamp = PrismicDate(item.data.ending_date)
                        const formattedEndingTimestamp = Intl.DateTimeFormat("fi", {
                          year: "numeric",
                          month: "numeric",
                          day: "numeric",
                        }).format(endingTimestamp)

                        let eventDate = ""
                        if (formattedStartingTimestamp === formattedEndingTimestamp) {
                          eventDate = formattedStartingTimestamp
                        } else if (
                          formattedStartingTimestamp !== formattedEndingTimestamp &&
                          formattedStartingTimestamp.substring(formattedStartingTimestamp.length - 4) ===
                            formattedEndingTimestamp.substring(formattedEndingTimestamp.length - 4)
                        ) {
                          eventDate = `${formattedStartingTimestamp.substring(
                            0,
                            formattedStartingTimestamp.length - 4
                          )}–${formattedEndingTimestamp}`
                        } else {
                          eventDate = `${formattedStartingTimestamp}–${formattedEndingTimestamp}`
                        }

                        return (
                          <div className="search-results-grid__item" key={i}>
                            <div className="item-image" aria-hidden="true">
                              <Link
                                to={`events/${item.uid}?lang=${language}`}
                                aria-label={item.data.title[0] && item.data.title[0].text}
                              >
                                {item.data.featured_image.url ? (
                                  <ProgressiveImage
                                    preview={item.data.featured_image.preview.url}
                                    src={item.data.featured_image.url}
                                    render={(src, style) => (
                                      <div style={Object.assign(style, { backgroundImage: `url(${src})` })} />
                                    )}
                                  />
                                ) : (
                                  <div />
                                )}
                              </Link>
                            </div>
                            <div className="item-content">
                              <div className="meta">
                                {item.tags && (
                                  <ul className="tags">
                                    {item.tags.map((tagTerm, i) => (
                                      <li key={i} className="tag">
                                        <button
                                          onClick={() => handleTagChange(tagTerm)}
                                          aria-label={
                                            language === "fi"
                                              ? `Näytä tapahtumat avainsanalla: ${tagTerm}`
                                              : `Show events with term: ${tagTerm}`
                                          }
                                        >
                                          {tagTerm}
                                        </button>
                                      </li>
                                    ))}
                                  </ul>
                                )}
                              </div>
                              <Link to={`events/${item.uid}?lang=${language}`}>
                                <h3>{item.data.title[0] && item.data.title[0].text}</h3>
                              </Link>
                              <div className="icon-wrap">
                                <ion-icon name="calendar-outline" style={{ fontSize: "20px" }}></ion-icon>
                                <p>{eventDate}</p>
                              </div>
                              {item.data.time[0] && item.data.time[0].text !== "" && (
                                <div className="icon-wrap">
                                  <ion-icon name="time-outline" style={{ fontSize: "20px" }}></ion-icon>
                                  <p>{item.data.time[0].text}</p>
                                </div>
                              )}
                              {item.data.place[0] && item.data.place[0].text !== "" && (
                                <div className="icon-wrap">
                                  <ion-icon name="pin-outline" style={{ fontSize: "20px" }}></ion-icon>
                                  <p>{item.data.place[0].text}</p>
                                </div>
                              )}
                              {item.data.excerpt[0] && item.data.excerpt[0].text !== "" && (
                                <div className="excerpt">
                                  <RichText render={item.data.excerpt} serializeHyperlink={customLink} />
                                </div>
                              )}
                              <ReadMore
                                label={item.data.title[0] && item.data.title[0].text}
                                url={`events/${item.uid}${language !== "fi" ? `?lang=${language}` : ""}`}
                              />
                            </div>
                          </div>
                        )
                      })
                  ) : (
                    <h2 className="search-results-grid__item">{language === "fi" ? "Ei tuloksia" : "No results"}</h2>
                  )}
                  {eventCount < documents.length && (
                    <button onClick={() => setEventCount(eventCount + 2)} className="button events__show-more">
                      {language === "fi" ? "Näytä lisää" : "Show more"}
                    </button>
                  )}
                </div>
              </div>
            </div>
          </CSSTransition>
        )}
      </TransitionGroup>
    </>
  )
}

export default ArchiveEvent
