import React, { useState, useEffect, useContext, useCallback } from "react"
import Prismic from "prismic-javascript"
import client from "../prismic-configuration"
import Header from "./Header"
import Masonry from "react-masonry-css"
import ArchiveItem from "./ArchiveItem"
import { LanguageContext } from "../hooks/use-language"

const Related = (props) => {
  const { tags, excludeUID, type } = props
  const [documents, setDocuments] = useState([])
  const { language } = useContext(LanguageContext)

  const getDocuments = useCallback(async (lang, tags, type, excludeUID) => {
    await client
      .query(
        [
          Prismic.Predicates.any("document.tags", tags),
          Prismic.Predicates.at("document.type", "news"),
          Prismic.Predicates.not(`my.${type}.uid`, excludeUID),
        ],
        {
          lang: lang,
          pageSize: 3,
          page: 1,
          orderings: "[document.first_publication_date desc]",
        }
      )
      .then((res) => {
        setDocuments(res.results)
      })
      .catch((err) => console.log(err))
  }, [])

  useEffect(() => {
    if (language) {
      getDocuments(language, tags, type, excludeUID)
    }
  }, [language, getDocuments, tags, type, excludeUID])

  const breakpointColumnsObj = {
    default: 3,
    992: 3,
    991: 1,
  }

  return (
    documents.length > 0 && (
      <div className="related">
        <Header breadcrumb={false} text={language === "fi" ? "Aiheeseen liittyvää" : "Related posts"} />
        <div className="container-fluid">
          <Masonry
            breakpointCols={breakpointColumnsObj}
            className="masonry-grid"
            columnClassName="masonry-grid__column"
          >
            {documents.map((item, i) => (
              <ArchiveItem key={item.uid} item={item} />
            ))}
          </Masonry>
        </div>
      </div>
    )
  )
}

export default Related
