import React from "react"
import { InView } from "react-intersection-observer"
import { WindupChildren, Pace } from "windups"

const Typewriter = ({ size, color, text }) => {
	let Title = "h1"
	if (size) Title = size

	const newTextColor = color?.includes("#") ? color : undefined

	return (
		<InView triggerOnce={true}>
			{({ inView, ref, entry }) => (
				<div ref={ref} className={"typewriter-container"}>
					{inView && (
						<Title className={color ? `animated-text ${color}` : "animated-text"} aria-hidden="true" style={{ color: newTextColor }}>
							<WindupChildren>
								<Pace ms={40}>{text}</Pace>
							</WindupChildren>
						</Title>
					)}
					<Title className="screen-reader-text" style={{ color: newTextColor }}>
						{text}
					</Title>
				</div>
			)}
		</InView>
	)
}

export default Typewriter
