import React, { useContext } from "react"
import { Link } from "react-router-dom"
import { LanguageContext } from "../hooks/use-language"

const ReadMore = (props) => {
  const { language } = useContext(LanguageContext)

  return (
    <div className="read-more">
      <p>
        {props.external ? (
          <a
            onClick={props.handleNavClose}
            href={props.url}
            aria-label={language === "fi" ? `Lue lisää aiheesta ${props.label}` : `Read more about ${props.label}`}
          >
            {language === "fi" ? "Lue lisää" : "Read more"}
          </a>
        ) : (
          <Link
            onClick={props.handleNavClose}
            to={props.url}
            aria-label={language === "fi" ? `Lue lisää aiheesta ${props.label}` : `Read more about ${props.label}`}
          >
            {language === "fi" ? "Lue lisää" : "Read more"}
          </Link>
        )}
      </p>
    </div>
  )
}

export default ReadMore
