import React, { useContext, useRef, useEffect } from "react"
import { Parallax } from "react-parallax"
import ProgressiveImage from "react-progressive-image-loading"
import { RichText } from "prismic-reactjs"
import customLink from "../customLink"
import { useInViewport } from "react-in-viewport"
import { LanguageContext } from "../hooks/use-language"

const HighlightSection = ({ slice, i }) => {
  const { language } = useContext(LanguageContext)
  const ref = useRef()
  const { inViewport } = useInViewport(ref)

  const handleVideo = (index, eventType) => {
    const video = document.getElementById(`video-${index}`)
    if (video) {
      switch (eventType) {
        case "play":
          video.play()
          video.parentElement.classList.remove("is-paused")
          video.parentElement.classList.add("is-playing")
          video.onended = () => {
            video.parentElement.classList.remove("is-playing")
            video.parentElement.classList.remove("is-paused")
            video.parentElement.classList.add("is-ended")
          }
          break
        case "pause":
          video.pause()
          video.parentElement.classList.remove("is-playing")
          video.parentElement.classList.add("is-paused")
          break
        case "volumeUp":
          video.muted = false
          if (video.volume < 1) {
            video.volume = Math.round((video.volume + 0.2) * 10) / 10
          }
          break
        case "volumeDown":
          video.muted = false
          if (video.volume > 0) {
            video.volume = Math.round((video.volume - 0.2) * 10) / 10
          }
          break
        default:
          break
      }
    }
  }

  useEffect(() => {
    const hasAudio = (video) => {
      return video.mozHasAudio || Boolean(video.webkitAudioDecodedByteCount) || Boolean(video.audioTracks?.length)
    }
    const video = ref.current
    if (video && inViewport) {
      if (!hasAudio(video)) {
        video.parentElement.classList.add("no-audio")
      } else video.volume = 0.6
      if (!video.parentElement.classList.contains("is-paused") && !video.parentElement.classList.contains("is-ended")) {
        video.play()
        video.parentElement.classList.add("is-playing")
        video.onended = () => {
          video.parentElement.classList.remove("is-playing")
          video.parentElement.classList.remove("is-paused")
          video.parentElement.classList.add("is-ended")
        }
      }
    }
  }, [inViewport, ref])

  return (
    <div
      className={`highlight-section ${
        !slice.primary.alignment ? "highlight-section--alignment-left" : "highlight-section--alignment-right"
      }`}
    >
      <div className="container">
        <div className="narrow-grid">
          <div className="narrow-grid__item--full-width">
            <div className="highlight-section__title-wrap">
              <Parallax
                strength={500}
                renderLayer={(percentage) => {
                  return (
                    <div
                      className="highlight-section__content"
                      style={{ transform: `translate(0, -${percentage * 100}px)` }}
                    >
                      {slice.primary.title1 && <h2>{RichText.asText(slice.primary.title1)}</h2>}
                    </div>
                  )
                }}
              />
            </div>
            <div className="highlight-section__media">
              {slice.primary.theme && (
                <Parallax
                  className="parallax-icon-wrap"
                  strength={500}
                  renderLayer={(percentage) => {
                    return (
                      <div
                        className={`highlight-section__parallax-icon ${slice.primary.theme}`}
                        style={{ transform: `translate(0, -${percentage * 100}px)` }}
                      />
                    )
                  }}
                />
              )}
              {slice.primary.image.url && (
                <ProgressiveImage
                  key={`${slice.primary.image.url}`}
                  preview={slice.primary.image.preview}
                  src={slice.primary.image.url}
                  render={(src, style) => (
                    <img style={style} className="highlight-section__image" src={src} alt={slice.primary.image.alt} />
                  )}
                />
              )}
              {slice.primary.video.url && (
                <div className="highlight-section__video-wrap">
                  <video
                    ref={ref}
                    id={`video-${i}`}
                    className="highlight-section__video"
                    muted
                    onLoadStart={(e) => {
                      const video = e.target
                      video.classList.add("is-loading")
                    }}
                    onLoadedData={(e) => {
                      const video = e.target
                      video.classList.remove("is-loading")
                      video.classList.add("is-loaded")
                    }}
                    preload="auto"
                    onClick={(e) => {
                      const video = e.target
                      const isVideoPlaying = !!(
                        video.currentTime > 0 &&
                        !video.paused &&
                        !video.ended &&
                        video.readyState > 2
                      )
                      if (!isVideoPlaying) {
                        video.play()
                        video.parentElement.classList.remove("is-paused")
                        video.parentElement.classList.add("is-playing")
                      } else {
                        video.pause()
                        video.parentElement.classList.remove("is-playing")
                        video.parentElement.classList.add("is-paused")
                      }
                      video.onended = () => {
                        video.parentElement.classList.remove("is-playing")
                        video.parentElement.classList.remove("is-paused")
                        video.parentElement.classList.add("is-ended")
                      }
                    }}
                  >
                    <source src={slice.primary.video.url} type="video/mp4" />
                  </video>
                  <div className="highlight-section__video-controls">
                    <button className="play" onClick={() => handleVideo(i, "play")}>
                      <ion-icon name="play-sharp"></ion-icon>
                      {language !== "fi" ? "Play video" : "Toista video"}
                    </button>
                    <button className="pause" onClick={() => handleVideo(i, "pause")}>
                      <ion-icon name="pause-sharp"></ion-icon>
                      {language !== "fi" ? "Pause video" : "Pysäytä video"}
                    </button>
                    <span>
                      <button
                        className="volume-down"
                        onClick={() => handleVideo(i, "volumeDown")}
                        aria-label={language !== "fi" ? "Decrease the volume" : "Vähennä äänenvoimakkuutta"}
                      >
                        <ion-icon name="volume-low-sharp"></ion-icon>
                      </button>
                      <button
                        className="volume-up"
                        onClick={() => handleVideo(i, "volumeUp")}
                        aria-label={language !== "fi" ? "Increase the volume" : "Lisää äänenvoimakkuutta"}
                      >
                        <ion-icon name="volume-high-sharp"></ion-icon>
                      </button>
                    </span>
                  </div>
                </div>
              )}

              <div className="highlight-section__content-wrap">
                <Parallax
                  strength={500}
                  renderLayer={(percentage) => {
                    return (
                      <div
                        className="highlight-section__content"
                        style={{ transform: `translate(0, -${percentage * 100}px)` }}
                      >
                        {slice.primary.content && (
                          <RichText render={slice.primary.content} serializeHyperlink={customLink} />
                        )}
                      </div>
                    )
                  }}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default HighlightSection
