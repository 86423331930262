import { createContext, useContext, useRef, useState } from "react"

const HeroRefContext = createContext()

export function HeroRefProvider({ children }) {
  const heroRef = useRef(null)
  const [heroRendered, setHeroRendered] = useState(false)

  return (
    <HeroRefContext.Provider value={{ heroRef: heroRef, heroRendered, setHeroRendered }}>
      {children}
    </HeroRefContext.Provider>
  )
}

export function useHeroRef() {
  return useContext(HeroRefContext)
}
