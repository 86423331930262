import React, { useContext, useEffect } from "react"
import FocusTrap from "focus-trap-react"
import { Link } from "react-router-dom"
import { LanguageContext } from "../hooks/use-language"

const NewLetterForm = (props) => {
  const { language } = useContext(LanguageContext)

  useEffect(() => {
    const script = document.createElement("script")
    script.src = "https://js.createsend1.com/javascript/copypastesubscribeformlogic.js"
    script.async = true
    document.body.appendChild(script)
    return () => {
      document.body.removeChild(script)
    }
  }, [])

  return (
    <>
      <FocusTrap active={props.newsletterActive ? true : false}>
        <div className="newsletter" role="dialog" aria-hidden={props.newsletterActive ? "false" : "true"}>
          <div className={props.newsletterActive ? "newsletter__modal is-active" : "newsletter__modal"}>
            <div className="newsletter__modal-header">
              <h2>{language === "fi" ? "Tilaa uutiskirje" : "Subscribe to our newsletter"}</h2>
              <button
                tabIndex={props.newsletterActive ? "0" : "-1"}
                className="newsletter__close"
                onClick={() => props.handleNewsLetter()}
                aria-label={language === "fi" ? "Sulje" : "Close"}
              >
                {/* <ion-icon name="close-sharp" style={{ fontSize: "27px" }}></ion-icon> */}✕
              </button>
            </div>
            <div className="newsletter__form">
              <form
                className="js-cm-form"
                id="subForm"
                action="https://www.createsend.com/t/subscribeerror?description="
                method="post"
                data-id="5B5E7037DA78A748374AD499497E309EC52952824435AC688408865E09D81CD02BD2C71EE951C3075EA302FDCA4A92E54F66C835FC8E61EFDA0DE30E98C79162"
              >
                <div>
                  <div className="form-field">
                    <label htmlFor="fieldEmail">{language === "fi" ? "Sähköposti*" : "Email*"}</label>
                    <input
                      tabIndex={props.newsletterActive ? "0" : "-1"}
                      placeholder={language === "fi" ? "Sähköposti*" : "Email*"}
                      autoComplete="email"
                      aria-label="Email"
                      className="js-cm-email-input qa-input-email"
                      id="fieldEmail"
                      maxLength="200"
                      name="cm-buuiyik-buuiyik"
                      required
                      type="email"
                    />
                  </div>

                  <div className="form-field">
                    <label htmlFor="fielddintjkk">{language === "fi" ? "Etunimi" : "First name"}</label>
                    <input
                      tabIndex={props.newsletterActive ? "0" : "-1"}
                      placeholder={language === "fi" ? "Etunimi" : "First name"}
                      type="text"
                      aria-label="first name"
                      id="fielddhluuyhk"
                      maxLength="200"
                      name="cm-f-dhluuyhk"
                    />
                  </div>

                  <div className="form-field">
                    <label htmlFor="fielddintjku">{language === "fi" ? "Sukunimi" : "Last name"}</label>
                    <input
                      tabIndex={props.newsletterActive ? "0" : "-1"}
                      placeholder={language === "fi" ? "Sukunimi" : "Last name"}
                      type="text"
                      aria-label="last name"
                      id="fielddhluuyhu"
                      maxLength="200"
                      name="cm-f-dhluuyhu"
                    />
                  </div>

                  <div className="form-field">
                    <label htmlFor="fielddintjur">{language === "fi" ? "Yritys" : "Organization"}</label>
                    <input
                      tabIndex={props.newsletterActive ? "0" : "-1"}
                      placeholder={language === "fi" ? "Yritys" : "Organization"}
                      type="text"
                      aria-label="organization"
                      id="fielddhluuykr"
                      maxLength="200"
                      name="cm-f-dhluuykr"
                    />
                  </div>
                  <div>
                    <div>
                      <div className="form-checkbox">
                        <input
                          tabIndex={props.newsletterActive ? "0" : "-1"}
                          required
                          // eslint-disable-next-line jsx-a11y/aria-proptypes
                          aria-required=""
                          id="cm-privacy-consent"
                          name="cm-privacy-consent"
                          type="checkbox"
                        />
                        <label htmlFor="cm-privacy-consent">
                          {language === "fi" ? "Hyväksyn sähköpostien vastaanottamisen*" : "I agree to be emailed*"}
                        </label>
                      </div>
                      <input
                        tabIndex={props.newsletterActive ? "0" : "-1"}
                        id="cm-privacy-consent-hidden"
                        name="cm-privacy-consent-hidden"
                        type="hidden"
                        value="true"
                      />
                    </div>
                  </div>
                  <p style={{ marginBottom: "16px" }}>
                    <Link tabIndex={props.newsletterActive ? "0" : "-1"} to="/privacy-policy">
                      {language === "fi" ? "Tietosuojaseloste" : "Privacy Policy"}
                    </Link>
                  </p>
                </div>
                <button tabIndex={props.newsletterActive ? "0" : "-1"} className="button" type="submit">
                  {language === "fi" ? "Tilaa" : "Subscribe"}
                </button>
                <p className="required-info">{language === "fi" ? `*Pakolliset kentät` : "*Required field"}</p>
              </form>
            </div>
          </div>
          <div
            tabIndex={props.newsletterActive ? "0" : "-1"}
            className={props.newsletterActive ? "newsletter__overlay is-active" : "newsletter-overlay"}
            onClick={() => props.handleNewsLetter()}
            aria-hidden={props.newsletterActive ? "false" : "true"}
          />
        </div>
      </FocusTrap>
    </>
  )
}

export default NewLetterForm
