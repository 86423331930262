import React from "react"
import AnalyticsCard from "./AnalyticsCard"

const Analytics = ({ data }) => {
  const {
    primary: { analyticstitle },
    items,
  } = data

  return (
    <div className="analytics">
      <h2 className="analytics_title">{analyticstitle ? analyticstitle.map((title) => title.text).join(" ") : ""}</h2>
      <div className="analytics_cards_container">
        {items &&
          items.map((item, index) => (
            <AnalyticsCard
              key={item.cardtitle[0].text}
              index={index}
              title={item.cardtitle.map((t) => t.text).join(" ")}
              figure={item.cardfigure.map((t) => t.text).join(" ")}
            />
          ))}
      </div>
    </div>
  )
}

export default Analytics
