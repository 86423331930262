import React, { useContext, useEffect } from "react"
import { Parallax } from "react-parallax"
import NewsLetterForm from "./NewsLetterForm"
import { ReactComponent as ParallaxSubscribe } from "../icons/subscribe.svg"
import { useSubscribe } from "../hooks/use-subscribe"
import { LanguageContext } from "../hooks/use-language"

const Subscribe = ({ title, buttonText }) => {
  const { language } = useContext(LanguageContext)
  const { handleNewsLetter, newsletterActive, setNewsletterActive } = useSubscribe()

  useEffect(() => {
    const timer = setTimeout(() => {
      if (document.location.hash.includes("#subscribe")) {
        setNewsletterActive(true)
      }
    }, 1000)
    return () => clearTimeout(timer)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <div className="subscribe_container" aria-label={language === "fi" ? "Tilaa kontti" : "Suncribe container"}>
      <div className="subscribe_parallax">
        <Parallax
          strength={1000}
          renderLayer={(percentage) => (
            <ParallaxSubscribe style={{ transform: `translate(0, -${percentage * 100}px)` }} />
          )}
        />
      </div>
      <div className="subscribe">
        {title ? <p className="subscribe_text">{title}</p> : null}
        {buttonText ? (
          <button
            type="button"
            onClick={(e) => {
              e.preventDefault()
              handleNewsLetter()
            }}
            className="subscribe_button"
          >
            {buttonText}
          </button>
        ) : null}
      </div>
      <NewsLetterForm newsletterActive={newsletterActive} handleNewsLetter={handleNewsLetter} />
    </div>
  )
}

export default Subscribe
