import React, { useContext } from "react"
import { Parallax } from "react-parallax"
import { getTextColorForBackground, getFirstSentence } from "../helpers/helper-functions"
import ArchiveSearch from "./ArchiveSearch"
import { ReactComponent as ParallaxStories } from "../icons/stories.svg"
import { LanguageContext } from "../hooks/use-language"

const ArchiveHero = ({
  text,
  title,
  buttons,
  bgcolor = "#EEEEEE",
  onClick,
  onInputChange,
  inputValue,
  theme,
  highlightFirstSentence,
}) => {
  const { language } = useContext(LanguageContext)
  const textColor = getTextColorForBackground(bgcolor)
  const allButtons = [
    {
      theme_link: { uid: language === "fi" ? "Kaikki" : "All" },
      theme_question_1: [{ text: language === "fi" ? "Kaikki" : "All" }],
    },
  ].concat(buttons)

  let firstSentence = ""
  let newText = ""

  // If first sentence needs to be highlighted with bold
  if (highlightFirstSentence) {
    firstSentence = getFirstSentence(text).first
    newText = getFirstSentence(text).rest
  } else {
    newText = text ? text : ""
  }

  const sentences = newText.split("\n\n")
  const splitedText = sentences.map((sentence, index) => (
    <React.Fragment key={index}>
      {sentence}
      <br />
      <br />
    </React.Fragment>
  ))

  return (
    <div className="hero_archive stories" style={{ backgroundColor: bgcolor }}>
      <div className="hero_column_archive left stories">
        <h1 className="hero_title" style={{ color: textColor }}>
          {title}
        </h1>
        {highlightFirstSentence ? (
          <p className="hero_text bold stories" style={{ color: textColor }}>
            {firstSentence}
          </p>
        ) : null}
        <p className="hero_text stories" style={{ color: textColor }}>
          {newText.includes("\n\n") ? splitedText : newText}
        </p>
        <div
          className="hero_archive_stories_buttons"
          aria-label={language === "fi" ? "Tarinoiden alinta" : "Stories selection"}
        >
          {buttons
            ? allButtons.map((btn, index) => (
                <button
                  key={btn.theme_link.uid + index}
                  type="button"
                  className={theme === btn.theme_link.uid ? "stories_button selected" : "stories_button"}
                  style={{ color: textColor }}
                  onClick={() => {
                    onClick(btn.theme_link.uid, btn.theme_question_1[0].text)
                  }}
                >
                  {btn.theme_question_1[0].text}
                </button>
              ))
            : null}
        </div>
        <div style={{ marginTop: 40 }} />
        <ArchiveSearch onChange={onInputChange} value={inputValue} />
      </div>
      <div className="hero_column_archive right stories">
        <Parallax
          strength={1000}
          renderLayer={(percentage) => (
            <div style={{ transform: `translate(0, -${percentage * 100}px)`, paddingTop: 90 }}>
              <ParallaxStories />
            </div>
          )}
        />
      </div>
    </div>
  )
}

export default ArchiveHero
