/* eslint-disable react-hooks/exhaustive-deps */
import { useContext, useEffect } from "react"
import { CurrentPageContext } from "../components/CurrentPageContext"

export const useCurrentPage = (path) => {
  const { currentPagePath, setCurrentPagePath } = useContext(CurrentPageContext)

  useEffect(() => {
    if (path) setCurrentPagePath(path.toString())
  }, [path])

  return currentPagePath
}
