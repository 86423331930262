import React from "react"

const AboutCard = ({ title, text, link, linkLabel, spans, index }) => {
  //split sentences if \n\n are present in text
  const handleText = () => {
    const lines = text
      .split("\n")
      .map((line) => line.trim())
      .filter((el) => el !== "")
    /// skip lines that are "//"
    const address1 = lines[8] ? (lines[8] === "//" ? "" : lines[8]) : ""
    const address2 = lines[9] ? (lines[9] === "//" ? "" : lines[9]) : ""
    const object = {
      text1: lines[0] === "//" ? "" : lines[0],
      name1: lines[1] === "//" ? "" : lines[1],
      title1: lines[2] === "//" ? "" : lines[2],
      email1: lines[3] === "//" ? "" : lines[3],
      name2: lines[4] === "//" ? "" : lines[4],
      title2: lines[5] === "//" ? "" : lines[5],
      email2: lines[6] === "//" ? "" : lines[6],
      commonEmail: lines[7] === "//" ? "" : lines[7],
      address: address1 + " " + address2,
      phone: lines[10] ? (lines[10] === "//" ? "" : lines[10]) : "",
    }
    return object
  }

  const newText = handleText()

  const handleSpans = () => {
    let modifiedText = ""

    let currentIndex = 0
    spans.forEach((span) => {
      const { start, end, type, data } = span

      // Add the text before the current span
      modifiedText += text.substring(currentIndex, start)
      // Add the modified text within the span
      let tag
      if (type === "hyperlink") {
        tag = "a"
      } else {
        tag = type
      }

      modifiedText += `<${tag} href=${type === "hyperlink" ? data.url : null}>${text.substring(start, end)}</${tag}>`

      currentIndex = end
    })
    // Add the remaining text after the last span
    modifiedText += text.substring(currentIndex)

    return <p dangerouslySetInnerHTML={{ __html: modifiedText }} />
  }

  return (
    <div className="about_card">
      <h2 className="about_title" id="about_title">
        {title}
      </h2>
      {index === 5 ? (
        <div>
          <div className="about_text contacts">
            {newText.text1 ? <p className="about_text">{newText.text1}</p> : null}
          </div>
          <div className="about_text contacts">
            {newText.name1 ? <strong className="about_text">{newText.name1}</strong> : null}
            {newText.title1 ? <p className="about_text">{newText.title1}</p> : null}
            <p className="about_text">
              <a href={`mailto:${newText.email1}`}>{newText.email1}</a>
            </p>
          </div>
          {newText.name2 ? (
            <div className="about_text contacts">
              <strong className="about_text">{newText.name2}</strong>
              {newText.title2 ? <p className="about_text">{newText.title2}</p> : null}
              <p className="about_text">
                <a href={`mailto:${newText.email2}`}>{newText.email2}</a>
              </p>
            </div>
          ) : null}
          <div className="about_text contacts">
            {newText.commonEmail ? (
              <p className="about_text">
                <a href={`mailto:${newText.commonEmail}`}>{newText.commonEmail}</a>
              </p>
            ) : null}
            {newText?.address ? <p className="about_text">{newText.address}</p> : null}
            {newText?.phone ? <p className="about_text">{newText.phone}</p> : null}
          </div>
        </div>
      ) : spans?.length > 0 ? (
        handleSpans()
      ) : (
        <p className="about_text">
          {text} {link ? <a href={link}>{linkLabel}</a> : null}
        </p>
      )}
    </div>
  )
}

export default AboutCard
