import React, { useContext } from "react"
import { ReactComponent as SearchIcon } from "../icons/icon-search.svg"
import { LanguageContext } from "../hooks/use-language"

const ArchiveSearch = ({ onChange, value }) => {
  const { language } = useContext(LanguageContext)

  return (
    <div className="stories_search">
      <SearchIcon />
      <input
        aria-label={language === "fi" ? "Hakukenttä" : "Search field"}
        role="search"
        autoComplete="off"
        type="text"
        placeholder={language === "fi" ? "Haku..." : "Search stories..."}
        onChange={onChange}
        value={value}
        name={language === "fi" ? "Haku" : "Search"}
      />
    </div>
  )
}

export default ArchiveSearch
