import React, { useState } from "react"
import { Helmet } from "react-helmet-async"

const TitleAnnouncer = (props) => {
  const [title, setTitle] = useState("")
  const onHelmetChange = ({ title }) => {
    setTitle(title)
    props.setFocus()
  }

  return (
    <>
      {title !== "" && (
        <p tabIndex="-1" className="sr-only" role="status">
          {title}
        </p>
      )}

      <Helmet onChangeClientState={onHelmetChange} />
    </>
  )
}

export default TitleAnnouncer
