import React from "react"
import { createContext, useState } from "react"

export const CurrentPageContext = createContext()

const CurrentPageContextProvider = ({ children }) => {
  const [currentPagePath, setCurrentPagePath] = useState(null)
  const [newsletterActive, setNewsletterActive] = useState(false)

  return (
    <CurrentPageContext.Provider value={{ currentPagePath, setCurrentPagePath, newsletterActive, setNewsletterActive }}>
      {children}
    </CurrentPageContext.Provider>
  )
}

export default CurrentPageContextProvider
