import { useState, useEffect, useContext, useCallback } from "react"
import client from "../prismic-configuration"
import { useCurrentPage } from "./use-current-page"
import Prismic from "prismic-javascript"
import { LanguageContext } from "./use-language"

export const useArchivePage = (match) => {
  useCurrentPage(match.url)
  const { language } = useContext(LanguageContext)
  const [theme, setTheme] = useState(language === "fi" ? "Kaikki" : "All")
  const [heroStories, setHeroStories] = useState(null)
  const [subscribeDoc, setSubscribeDoc] = useState(null)
  const [search, setSearch] = useState("")
  const [documents, setDocuments] = useState([])
  const [totalPages, setTotalPages] = useState(0)
  const [filteredDocuments, setFilteredDocuments] = useState([])
  const [filteredTotalPages, setFilteredTotalPages] = useState(0)
  const [currentPage, setCurrentPage] = useState(1)
  const [searchDocuments, setSearchDocuments] = useState([])
  const [totalSearchPages, setTotalSearchPages] = useState(0)
  const [currentSearchPage, setCurrentSearchPage] = useState(1)
  const [tag, setTag] = useState("")
  const [pages, setPages] = useState([])
  const [doc, setDoc] = useState({
    title: "",
    uid: "",
    meta_description: "",
    hero_image: {},
    data: [],
  })

  const finalDocumentsNotSorted =
    filteredDocuments.length > 0 ? filteredDocuments : searchDocuments.length > 0 ? searchDocuments : documents
  const finalNumberOfPages = filteredTotalPages ? filteredTotalPages : totalSearchPages ? totalSearchPages : totalPages
  const finalDocuments = [...finalDocumentsNotSorted].sort((a, b) => {
    const dateA = new Date(a.first_publication_date)
    const dateB = new Date(b.first_publication_date)
    return dateB.getTime() - dateA.getTime()
  })

  const getDocuments = useCallback(async (tag, page, lang) => {
    const response = await client.query(
      tag !== ""
        ? Prismic.Predicates.at("document.tags", [tag.replace(/%20/g, " ")])
        : Prismic.Predicates.at("document.type", "news"),
      { lang: lang, pageSize: 9, page: page, orderings: "[document.first_publication_date desc]" }
    )
    if (response) {
      setDocuments(response.results)
      setTotalPages(response.total_pages)
      // Make array of page numbers to show
      const numbers = []
      for (let i = 0; i < response.total_pages; i++) {
        numbers.push(i + 1)
      }
      let pageNumbers
      if (window.outerWidth <= 768) {
        pageNumbers = numbers.filter((number) => {
          return number === page
        })
      } else {
        pageNumbers = numbers.filter((number) => {
          return number >= page - 4 && number <= page + 4
        })
      }
      setPages(pageNumbers)
    }
  }, [])

  const getLinkedDoc = useCallback(async (id) => {
    const res = await client.getByID(id)
    if (res) return res.data
  }, [])

  const getDocument = useCallback(
    async (lang) => {
      await client
        .getSingle("stories", { lang: lang })
        .then(async (res) => {
          setDoc({
            title: res.data.title[0]?.text,
            uid: res.uid,
            meta_description: res.data.meta_description[0]?.text,
            hero_image: res.data.hero_image,
            data: res.data.body ? res.data.body.filter((doc) => doc.slice_type === "text_card") : [],
          })

          if (res.data.subscribe) {
            const sub = await getLinkedDoc(res.data.subscribe.id)
            setSubscribeDoc(sub)
          } else {
            setSubscribeDoc(null)
          }
        })
        .catch((err) => console.log(err))
    },
    [getLinkedDoc]
  )

  useEffect(() => {
    if (language) {
      getDocument(language)
    }
  }, [getDocument, language])

  const handlePageChange = (page) => {
    if (searchDocuments.length > 0) {
      getMoreSearchResults(currentSearchPage + 1, language, search)
      setCurrentSearchPage(currentSearchPage + 1)
    } else {
      setCurrentPage(page)
    }
  }

  useEffect(() => {
    let page = 1
    let tag = ""
    if (match.params.number) {
      page = parseInt(match.params.number)
      setCurrentPage(page)
    }
    if (match.params.tag) {
      tag = match.params.tag
      setTag(tag)
    } else {
      setTag("")
    }
    if (language) {
      getDocuments(tag, page, language)
    }
  }, [getDocuments, language, match])

  useEffect(() => {
    setHeroStories({
      title: doc.data[0]?.primary.header_title.map((title) => title.text).join(" "),
      buttons: doc.data.length > 0 ? doc.data[0].items : [],
      bgcolor: "#EEEEEE",
      text: doc.data[0]?.primary.header_paragraph.map((title) => title.text).join(" "),
    })
  }, [doc.data, setHeroStories])

  const handleFilterStories = async (uid, type) => {
    setTheme(uid)
    setSearch("")
    setCurrentSearchPage(1)
    setTotalSearchPages(0)
    setSearchDocuments([])
    if (type === "Kaikki" || type === "All") {
      setFilteredDocuments([])
      setFilteredTotalPages(0)
    } else {
      await client
        .query([Prismic.Predicates.at("document.type", "news"), Prismic.Predicates.at("my.news.theme", uid)], {
          lang: language,
          pageSize: 9,
          page: currentPage,
          orderings: "[document.first_publication_date desc]",
        })
        .then((response) => {
          setFilteredDocuments(response.results)
          setFilteredTotalPages(response.total_pages)
        })
        .catch((e) => console.log(e))
    }
  }

  const getMoreSearchResults = useCallback(async (page, lang, search) => {
    await client
      .query(
        [
          Prismic.Predicates.any("document.type", ["news", "page", "home", "press_release", "events", "theme_page"]),
          Prismic.Predicates.fulltext("document", search),
        ],
        {
          lang: lang,
          pageSize: 9,
          page: page,
          orderings: "[document.first_publication_date desc]",
        }
      )
      .then((res) => {
        setSearchDocuments((docs) => {
          return docs.concat(res.results)
        })
      })
      .catch((err) => console.log(err))
  }, [])

  const getSearchResults = useCallback(async (lang, search) => {
    setTheme(lang === "fi" ? "Kaikki" : "All")
    setFilteredDocuments([])
    setFilteredTotalPages(0)
    await client
      .query(
        [
          Prismic.Predicates.any("document.type", ["news", "page", "home", "press_release", "events", "theme_page"]),
          Prismic.Predicates.fulltext("document", search),
        ],
        {
          lang: lang,
          pageSize: 9,
          page: 1,
          orderings: "[document.first_publication_date desc]",
        }
      )
      .then((res) => {
        setCurrentSearchPage(1)
        setTotalSearchPages(res.total_pages)
        setSearchDocuments(res.results)
      })
      .catch((err) => console.log(err))
  }, [])

  useEffect(() => {
    if (search.length > 2 && language) {
      const timer = setTimeout(() => {
        getSearchResults(language, search)
      }, 250)

      return () => {
        clearTimeout(timer)
      }
    } else {
      setCurrentSearchPage(1)
      setTotalSearchPages(0)
      setSearchDocuments([])
    }
  }, [search, language, getSearchResults])

  return {
    handlePageChange,
    handleFilterStories,
    theme,
    heroStories,
    subscribeDoc,
    search,
    setSearch,
    documents,
    currentPage,
    tag,
    pages,
    doc,
    finalDocuments,
    finalNumberOfPages,
  }
}
