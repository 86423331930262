import React, { useEffect } from "react"
import { Parallax } from "react-parallax"
import { Link } from "react-router-dom"
import Skeleton from "@mui/material/Skeleton"
import { WindupChildren, Pace } from "windups"

import { getTextColorForBackground } from "../helpers/helper-functions"
import { ReactComponent as Arrow } from "../icons/home-hero-arrow.svg"
import { useHeroRef } from "../context/HeroProvider"

const Hero = ({
  to,
  bgcolor = "#006969",
  logo = undefined,
  highlightFirstSentence = false,
  title,
  text,
  buttonText,
  animation = false,
  loading,
}) => {
  const { heroRef, setHeroRendered } = useHeroRef()
  //Get a text color depending on the lumncance of the backround
  const textColor = getTextColorForBackground(bgcolor)

  useEffect(() => {
    setHeroRendered(true)
  }, [setHeroRendered])

  if (typeof logo !== "undefined") {
    return (
      <div id={"about-hero"} className="hero with-logo" style={{ backgroundColor: bgcolor }} aria-label="hero">
        <div className="hero_left" aria-label="text_section">
          {loading ? (
            <Skeleton variant="text" sx={{ width: "100%", height: 80 }} />
          ) : (
            <h1 style={{ color: textColor, paddingTop: 20, paddingBottom: 20, textAlign: "left" }}>{title}</h1>
          )}

          {loading ? (
            <Skeleton variant="text" sx={{ width: "100%", height: 300 }} />
          ) : (
            <>
              <p className="hero_text with-logo" style={{ color: textColor }}>
                {text}
              </p>
            </>
          )}
          {buttonText ? (
            <Link to={to} className="button" style={{ color: textColor, borderColor: textColor }}>
              {buttonText}
            </Link>
          ) : null}
        </div>

        {logo ? logo : null}
      </div>
    )
  }
  return (
    <>
      <div id={"home-hero"} ref={heroRef} className="hero home" style={{ backgroundColor: bgcolor }} aria-label="hero">
        {loading ? (
          <Skeleton variant="text" sx={{ maxWidth: 600, width: "100%", height: 80 }} />
        ) : (
          <div className="hero_home_text_container">
            <WindupChildren>
              <Pace ms={40}>
                <h1 className="hero_title">{title}</h1>

                <p className="hero_home_text">{text}</p>
              </Pace>
            </WindupChildren>
          </div>
        )}
        {!loading ? (
          buttonText ? (
            <Link to={to} className="button">
              {buttonText}
            </Link>
          ) : null
        ) : (
          <Skeleton variant="button" sx={{ width: 250, height: 60, marginTop: 2 }} />
        )}
      </div>
      <div className="hero-arrow-container">
        <div className="hero-arrow-position">
          <Parallax
            strength={500}
            renderLayer={(percentage) => (
              <Arrow
                className="hero-arrow"
                style={{ transform: `translate(0, -${percentage * 100}px) rotate(90deg)` }}
              />
            )}
          />
        </div>
      </div>
    </>
  )
}

export default Hero
