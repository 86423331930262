import React, { useContext } from "react"
import { Link } from "react-router-dom"
import withBreadcrumbs from "react-router-breadcrumbs-hoc"
import { LanguageContext } from "../hooks/use-language"

const Breadcrumbs = ({ breadcrumbs, title }) => {
  const { language } = useContext(LanguageContext)

  return (
    <ul className="breadcrumbs" role="navigation" aria-label={language === "fi" ? "Murupolku" : "Breadcrumb"}>
      {breadcrumbs.map(({ breadcrumb, match }, i) => {
        let text
        if (breadcrumb.key === "/" || breadcrumb.key === "/home") {
          text = language === "fi" ? "Etusivu" : "Home"
        } else if (breadcrumb.key === "/stories") {
          text = language === "fi" ? "Jutut" : "Stories"
        } else if (breadcrumb.key === "/stories-tag") {
          text = language === "fi" ? "Jutut" : "Stories"
        } else if (breadcrumb.key === "/events") {
          text = language === "fi" ? "Tapahtumat" : "Events"
        } else if (breadcrumb.key === "/events-tag") {
          text = language === "fi" ? "Tapahtumat" : "Events"
        } else if (breadcrumb.key?.endsWith("page")) {
          text = language === "fi" ? "Sivu" : "Page"
        } else if (breadcrumb.key === "/themes") {
          text = null
        } else if (parseInt(breadcrumb.key?.slice(-1))) {
          text = breadcrumb.props.children
        } else if (breadcrumb.key?.includes("-tag")) {
          text = breadcrumb.props.children
        } else {
          text = title
        }

        if (text) {
          return (
            <li className="breadcrumbs-item" key={breadcrumb.key}>
              {i === breadcrumbs.length - 1 ? (
                <span aria-current="page">{text}</span>
              ) : (
                <Link to={`${match.url}?lang=${language}`}>{text}</Link>
              )}
            </li>
          )
        } else {
          return null
        }
      })}
    </ul>
  )
}

export default withBreadcrumbs()(Breadcrumbs)
