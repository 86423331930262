import { useState, useEffect, useContext, useCallback } from "react"
import client from "../prismic-configuration"
import { useCurrentPage } from "./use-current-page"
import { LanguageContext } from "./use-language"

export const useAboutPage = (match) => {
  useCurrentPage(match.url)
  const [aboutDoc, setAboutDoc] = useState({
    body: [],
    title: "",
    uid: "",
    tags: [],
  })
  const [heroDoc, setHeroDoc] = useState(null)
  const [subscribeDoc, setSubscribeDoc] = useState(null)
  const { language } = useContext(LanguageContext)

  const getLinkedDoc = useCallback(async (id) => {
    const res = await client.getByID(id)
    if (res) return res.data
  }, [])

  const getDocument = useCallback(
    async (lang) => {
      await client
        .getSingle("about", { lang: lang })
        .then(async (res) => {
          setAboutDoc({
            body: res.data.body,
            title: res.data.title,
            uid: res.uid,
            tags: res.tags,
          })

          if (res.data.subscribe) {
            const sub = await getLinkedDoc(res.data.subscribe.id)
            setSubscribeDoc(sub)
          } else {
            setSubscribeDoc(null)
          }

          if (res.data.hero) {
            const hero = await getLinkedDoc(res.data.hero.id)
            setHeroDoc(hero)
          } else {
            setHeroDoc(null)
          }
        })
        .catch((err) => console.log(err))
    },
    [getLinkedDoc]
  )

  useEffect(() => {
    if (language) {
      getDocument(language)
    }
  }, [getDocument, language, match])

  return { aboutDoc, heroDoc, subscribeDoc }
}
