import { useState, useEffect, useContext, useCallback } from "react"
import client from "../prismic-configuration"
import { useCurrentPage } from "./use-current-page"
import { useSubscribe } from "./use-subscribe"
import { LanguageContext } from "./use-language"

export const useHomePage = (match) => {
  const { handleNewsLetter, newsletterActive, setNewsletterActive } = useSubscribe()
  useCurrentPage(match.url)
  const [doc, setDoc] = useState({
    title: "",
    uid: "",
    meta_description: "",
    tags: [],
    hero_image: {},
    slices: [],
    maintenance_mode: "",
  })
  const [contentCards, setContentCards] = useState([])
  const [highlightDoc, setHighlightDoc] = useState(null)
  const [heroDoc, setHeroDoc] = useState(null)
  const [subscribeDoc, setSubscribeDoc] = useState(null)
  const [socialLinks, setSocialLinks] = useState([])
  const { language, switchLanguage } = useContext(LanguageContext)

  const analytics = doc.slices.find((d) => d.slice_type === "analytics")
  const highlight = doc.slices.find((d) => d.slice_type === "highlight")

  const getSocialLinks = useCallback(async (lang) => {
    const res = await client.getSingle("social_media_links", { lang: lang })
    if (res) setSocialLinks(res.data)
  }, [])

  const getLinkedDocs = useCallback(async (ids) => {
    const res = await client.getByIDs(ids, { pageSize: 100 })
    if (res) setContentCards(res.results)
  }, [])

  const getLinkedDoc = useCallback(async (id) => {
    const res = await client.getByID(id)
    if (res) return res.data
  }, [])

  const getDocument = useCallback(
    async (lang) => {
      await client
        .getSingle("home", { lang: lang.toLowerCase() })
        .then(async (res) => {
          setDoc({
            title: res.data.title[0]?.text ?? "",
            uid: res.uid ?? "",
            meta_description: res.data.meta_description[0]?.text ?? null,
            tags: res.tags ?? null,
            hero_image: res.data.hero_image ?? "",
            slices: res.data.body ?? null,
            maintenance_mode: res.data.maintenance_mode,
          })
          if (res.data.subscribe) {
            const sub = await getLinkedDoc(res.data.subscribe.id)
            setSubscribeDoc(sub)
          } else {
            setSubscribeDoc(null)
          }
          if (res.data.body) {
            const ids = []
            res.data.body.forEach((item) => {
              if (item.primary?.linked_document?.id) {
                ids.push(item.primary.linked_document.id)
              }
            })
            getLinkedDocs(ids)
          } else {
            setContentCards([])
          }
          if (res.data.hero) {
            const hero = await getLinkedDoc(res.data.hero.id)
            setHeroDoc(hero)
          } else {
            setHeroDoc(null)
          }
          getSocialLinks(lang)
        })
        .catch((err) => console.log(err))
    },
    [getSocialLinks, getLinkedDocs, getLinkedDoc]
  )

  useEffect(() => {
    if (language) {
      getDocument(language)
    }
    if (document.location.hash === "#subscribe") {
      setTimeout(() => {
        setNewsletterActive(true)
      }, 1000)
    } else if (document.location.hash === "#subscribe-en") {
      switchLanguage("en")
      setTimeout(() => {
        setNewsletterActive(true)
      }, 1000)
    }
  }, [getDocument, language, switchLanguage, match, setNewsletterActive])

  useEffect(() => {
    ;(async () => {
      if (highlight) {
        const readmore = await getLinkedDoc(highlight.primary.readmore.id)
        setHighlightDoc(readmore)
      }
    })()
  }, [highlight, getLinkedDoc])

  return {
    doc,
    contentCards,
    highlightDoc,
    heroDoc,
    newsletterActive,
    handleNewsLetter,
    socialLinks,
    analytics,
    highlight,
    subscribeDoc,
  }
}
