import React, { useState, useEffect, useContext, useCallback } from "react"
import Prismic from "prismic-javascript"
import client from "../prismic-configuration"
import { Helmet } from "react-helmet-async"
import Masonry from "react-masonry-css"
import ArchiveItem from "./ArchiveItem"
import Pagination from "./Pagination"
import { TransitionGroup, CSSTransition } from "react-transition-group"
import Header from "./Header"
import ProgressiveImage from "react-progressive-image-loading"
import { RichText } from "prismic-reactjs"
import customLink from "../customLink"
import NotFound from "./NotFound"
import { LanguageContext } from "../hooks/use-language"

const ArchiveNews = ({ match }) => {
  const [documents, setDocuments] = useState([])
  const [totalPages, setTotalPages] = useState(0)
  const [pages, setPages] = useState([])
  const [currentPage, setCurrentPage] = useState(1)
  const { language } = useContext(LanguageContext)
  const [doc, setDoc] = useState({
    title: "",
    override_title: "",
    meta_description: "",
    uid: "",
    header_image: {},
    header_content: [],
    theme_color: "",
  })
  const [redirect, setRedirect] = useState(false)

  const getDocument = useCallback(
    async (lang) => {
      await client
        .getByUID("theme_page", match.params.slug, { lang: lang })
        .then((res) => {
          setDoc({
            title: res.data.title[0]?.text,
            override_title: res.data.override_title[0]?.text,
            meta_description: res.data.meta_description[0]?.text,
            uid: res.uid,
            header_image: res.data.header_image,
            header_content: res.data.header_content,
            theme_color: res.data.theme_color,
          })
        })
        .catch((err) => {
          console.log(err)
          setRedirect(true)
        })
    },
    [match.params]
  )

  const getDocuments = useCallback(
    async (lang) => {
      const response = await client.query(
        [Prismic.Predicates.at("document.type", "news"), Prismic.Predicates.at("my.news.theme", match.params.slug)],
        { lang: lang, pageSize: 9, page: currentPage, orderings: "[document.first_publication_date desc]" }
      )

      if (response) {
        setDocuments(response.results)
        setTotalPages(response.total_pages)
        // Make array of page numbers to show
        const numbers = []
        for (let i = 0; i < response.total_pages; i++) {
          numbers.push(i + 1)
        }
        let pageNumbers
        if (window.outerWidth <= 768) {
          pageNumbers = numbers.filter((number) => {
            return number === currentPage
          })
        } else {
          pageNumbers = numbers.filter((number) => {
            return number >= currentPage - 4 && number <= currentPage + 4
          })
        }
        setPages(pageNumbers)
      }
    },
    [match.params, currentPage]
  )

  useEffect(() => {
    setCurrentPage(1)
    if (language) {
      getDocument(language)
      getDocuments(language)
    }
    if (match.params.number) {
      handlePageChange(parseInt(match.params.number))
    }
  }, [getDocument, getDocuments, currentPage, language, match])

  const handlePageChange = (i) => {
    setCurrentPage(i)
  }

  const breakpointColumnsObj = {
    default: 3,
    992: 3,
    991: 1,
  }

  return redirect ? (
    <NotFound />
  ) : (
    <>
      <Helmet>
        <title>{doc.title !== "" ? `${doc.title} - Finnish Design Info` : ""}</title>
        {doc.meta_description !== "" && <meta name="description" content={doc.meta_description} />}
      </Helmet>
      <TransitionGroup component={null}>
        {doc.uid && (
          <CSSTransition timeout={300} classNames="fade">
            <div className="archive">
              <Header color={doc.theme_color} text={doc.title} overrideText={doc.override_title} />
              <div className="container-fluid">
                <div className="theme-header">
                  <div className="container">
                    <div className="narrow-grid">
                      <div className="narrow-grid__item item--full-width">
                        {doc.header_image && (
                          <div className="image" aria-hidden="true">
                            <ProgressiveImage
                              key={doc.header_image.url}
                              preview={doc.header_image.preview.url}
                              src={doc.header_image.url}
                              render={(src, style) => <img src={src} style={style} alt={doc.header_image.alt} />}
                            />
                          </div>
                        )}
                        <div>
                          <RichText render={doc.header_content} serializeHyperlink={customLink} />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                {documents.length > 0 && (
                  <Masonry
                    breakpointCols={breakpointColumnsObj}
                    className="masonry-grid"
                    columnClassName="masonry-grid__column"
                  >
                    {documents.map((item) => (
                      <ArchiveItem key={item.uid} item={item} />
                    ))}
                  </Masonry>
                )}
              </div>
            </div>
          </CSSTransition>
        )}
      </TransitionGroup>
      {doc.uid && documents.length > 0 && (
        <Pagination
          slug={`themes/${match.params.slug}`}
          pages={pages}
          handlePageChange={handlePageChange}
          totalPages={totalPages}
          currentPage={currentPage}
        />
      )}
    </>
  )
}

export default ArchiveNews
