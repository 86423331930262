import React, { useContext } from "react"
import { Link } from "react-router-dom"
import ReadMore from "./ReadMore"
import { getTextColorForBackground } from "../helpers/helper-functions"
import ProgressiveImage from "react-progressive-image-loading"
import { isDesktop } from "react-device-detect"
import { LanguageContext } from "../hooks/use-language"

const Highlight = ({ bgcolor = "#006969", image, title, type, uid, text }) => {
  const { language } = useContext(LanguageContext)

  const url = type === "news" ? "/stories" : ""
  const finalUrl = `${url}/${uid}${language !== "fi" ? `?lang=${language}` : ""}`

  const textColor = getTextColorForBackground(bgcolor)

  const imageStyle = isDesktop ? { width: "100%", height: "100%", objectFit: "cover" } : { objectFit: "cover" }

  return (
    <div
      className="hero_archive highlight"
      style={{ backgroundColor: "transparent" }}
      aria-label={language === "fi" ? "Kohokohta" : "A highlight"}
      role="contentinfo"
    >
      <div className="hero_column_archive" style={{ backgroundColor: bgcolor, paddingBottom: 50 }} aria-hidden="true">
        <h2 className="hero_title" style={{ color: textColor }}>
          {title}
        </h2>
        <p style={{ paddingLeft: 20, paddingRight: 20 }}>{text}</p>
        <div className="hero_read-more" aria-hidden="true">
          <ReadMore label={title} url={finalUrl} />
        </div>
      </div>
      {image ? (
        <Link to={finalUrl} aria-hidden="true" className="hero_column_archive right">
          <ProgressiveImage
            preview={image?.url}
            src={image?.url}
            render={(src, style) => {
              return <img src={src} style={{ ...style, ...imageStyle }} alt={image.alt} />
            }}
          />
        </Link>
      ) : null}
    </div>
  )
}

export default Highlight
