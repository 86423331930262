import React, { createContext, useState, useEffect, useCallback } from "react"
import { useHistory, useLocation } from "react-router-dom"

export const LanguageContext = createContext()

export const LanguageProvider = ({ children }) => {
  const [language, setLanguage] = useState("fi") // Default language is Finnish
  const history = useHistory()
  const location = useLocation()

  // Declare switchLanguage as useCallback to prevent re-creation on each render
  const switchLanguage = useCallback(
    (language) => {
      setLanguage(language)
      localStorage.setItem("language", language)
      const params = new URLSearchParams(location.search)
      params.set("lang", language)
      history.replace({ search: params.toString() })
    },
    [location.search, history]
  )

  useEffect(() => {
    const savedLang = localStorage.getItem("language")
    if (savedLang) {
      switchLanguage(savedLang)
    } else {
      const params = new URLSearchParams(location.search)
      const lang = params.get("lang")
      if (lang && lang !== language) {
        switchLanguage(lang)
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location.search, language, switchLanguage])

  return <LanguageContext.Provider value={{ language, switchLanguage }}>{children}</LanguageContext.Provider>
}
