import React from "react"
import { Link } from "react-router-dom"

const params = new URLSearchParams(window.location.search)
const lang = params.get("lang")

// Custom links for Rich Text fields
const customLink = (type, element, content, children, index) => {
  // Link for news custom type
  if (element.data.type === "news")
    return (
      <Link to={`/news/${element.data.uid}?lang=${lang}`} key={index}>
        {content}
      </Link>
    )
  // Link for stories page
  else if (element.data.type === "stories")
    return (
      <Link to={`/stories?lang=${lang}`} key={index}>
        {content}
      </Link>
    )
  // Link for pages
  else if (element.data.type === "page")
    return (
      <Link to={`/${element.data.uid}?lang=${lang}`} key={index}>
        {content}
      </Link>
    )
  // Link for theme pages
  else if (element.data.type === "theme_page")
    return (
      <Link to={`/themes/${element.data.uid}?lang=${lang}`} key={index}>
        {content}
      </Link>
    )
  // Link for other Documents
  else if (element.data.link_type === "Document")
    return (
      <Link to={`/${element.data.uid}?lang=${lang}`} key={index}>
        {content}
      </Link>
    )
  // Link for web/media
  else if (element.data.link_type === "Web" || element.data.link_type === "Media")
    return (
      <a
        href={element.data.url}
        target={element.data.target && element.data.target === "_blank" ? "_blank" : ""}
        rel="noreferrer"
        key={index}
      >
        {content}
      </a>
    )
}

export default customLink
