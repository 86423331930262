import React from "react"
import Masonry from "react-masonry-css"

const breakpointColumnsObj = {
  default: 3,
  992: 3,
  991: 1,
}

const GridWrap = ({ children, noPaddings }) => {
  return (
    <Masonry
      breakpointCols={breakpointColumnsObj}
      className="masonry-grid"
      columnClassName={noPaddings ? "masonry-grid__column_no_padding" : "masonry-grid__column"}
    >
      {children}
    </Masonry>
  )
}

export default GridWrap
