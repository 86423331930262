import React from "react"
import ProgressiveImage from "react-progressive-image-loading"

const Person = (props) => {
	return (
		<div className="person">
			<div className="person__content">
				{props.person.data.image.url && (
					<div className="person__left">
						<ProgressiveImage preview={props.person.data.image.preview.url} src={props.person.data.image.url} render={(src, style) => <div className="person__image" style={Object.assign(style, { backgroundImage: `url(${src})` })} />} />
					</div>
				)}

				<div className="person__right">
					{props.person?.data?.name && <p>{props.person?.data?.name[0]?.text}</p>}
					{props.person?.data?.title && <p className="title">{props.person?.data?.title[0]?.text}</p>}
				</div>
			</div>
		</div>
	)
}

export default Person
