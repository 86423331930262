import React, { useState, useEffect, useContext, useCallback } from "react"
import client from "../prismic-configuration"
import { RichText } from "prismic-reactjs"
import customLink from "../customLink"
import { ReactComponent as FacebookIcon } from "../icons/facebook-icon.svg"
import { ReactComponent as LinkedinIcon } from "../icons/linkedin-icon.svg"
import { ReactComponent as TwitterIcon } from "../icons/twitter-icon.svg"
import { ReactComponent as InstagramIcon } from "../icons/instagram-icon.svg"
import { ReactComponent as LogoHorizontal1 } from "../icons/logo-horizontal-1.svg"
import { ReactComponent as LogoHorizontal2 } from "../icons/logo-horizontal-2.svg"
import { ReactComponent as LogoHorizontal3 } from "../icons/logo-horizontal-3.svg"
import { LanguageContext } from "../hooks/use-language"

const Footer = (props) => {
  const [document, setDocument] = useState({
    content_left: [],
    content_right: [],
  })
  const [socialLinks, setSocialLinks] = useState([])
  const { language } = useContext(LanguageContext)

  const logoVersion = Math.floor(Math.random() * 3) + 1
  let logoHorizontal
  if (logoVersion === 1) {
    logoHorizontal = <LogoHorizontal1 />
  } else if (logoVersion === 2) {
    logoHorizontal = <LogoHorizontal2 />
  } else {
    logoHorizontal = <LogoHorizontal3 />
  }

  const getDocument = useCallback(async (lang) => {
    await client
      .getSingle("footer", { lang: lang })
      .then((res) => {
        setDocument({
          content_left: res.data.content_left,
          content_right: res.data.content_right,
        })
      })
      .catch((err) => console.log(err))

    await client
      .getSingle("social_media_links", { lang: lang })
      .then((res) => {
        setSocialLinks(res.data)
      })
      .catch((err) => console.log(err))
  }, [])

  useEffect(() => {
    if (language) {
      getDocument(language)
    }
  }, [props.match, language, getDocument])

  if (document.content_left.length > 0) {
    return (
      <footer className="footer">
        <div className="footer__top">
          <div className="container">
            <div className="narrow-grid">
              <div className="narrow-grid__item item--full-width">
                <div className="logo-wrap">{logoHorizontal}</div>
                <div className="narrow-grid__item item--third-width item--social-links">
                  {socialLinks.instagram_link && socialLinks.instagram_link[0].text !== "" && (
                    <a
                      href={socialLinks.instagram_link[0].text}
                      rel="noopener noreferrer"
                      target="_blank"
                      aria-label={
                        language === "fi"
                          ? "Finnish Design Infon Instagram-sivu"
                          : "Instagram page of Finnish Design Info"
                      }
                    >
                      <InstagramIcon />
                    </a>
                  )}
                  {socialLinks.linkedin_link && socialLinks.linkedin_link[0].text !== "" && (
                    <a
                      href={socialLinks.linkedin_link[0].text}
                      rel="noopener noreferrer"
                      target="_blank"
                      aria-label={
                        language === "fi"
                          ? "Finnish Design Infon LinkedIn-sivu"
                          : "LinkedIn page of Finnish Design Info"
                      }
                    >
                      <LinkedinIcon />
                    </a>
                  )}
                  {socialLinks.twitter_link && socialLinks.twitter_link[0].text !== "" && (
                    <a
                      href={socialLinks.twitter_link[0].text}
                      rel="noopener noreferrer"
                      target="_blank"
                      aria-label={
                        language === "fi" ? "Finnish Design Infon Twitter-sivu" : "Twitter page of Finnish Design Info"
                      }
                    >
                      <TwitterIcon />
                    </a>
                  )}
                  {socialLinks.facebook_link && socialLinks.facebook_link[0].text !== "" && (
                    <a
                      href={socialLinks.facebook_link[0].text}
                      rel="noopener noreferrer"
                      target="_blank"
                      aria-label={
                        language === "fi"
                          ? "Finnish Design Infon Facebook-sivu"
                          : "Facebook page of Finnish Design Info"
                      }
                    >
                      <FacebookIcon />
                    </a>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="footer__bottom">
          <div className="container">
            <div className="narrow-grid">
              <div className="narrow-grid__item item--third-width">
                {document.content_left && <RichText render={document.content_left} serializeHyperlink={customLink} />}
              </div>
              <div className="narrow-grid__item item--third-width">
                {document.content_left && <RichText render={document.content_right} serializeHyperlink={customLink} />}
              </div>
            </div>
          </div>
        </div>
      </footer>
    )
  } else {
    return null
  }
}

export default Footer
