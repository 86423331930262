import React from "react"
import Typewriter from "./Typewriter"
import Breadcrumbs from "./Breadcrumbs"

const Header = (props) => {
	return (
		<div
			className="header"
			style={{
				marginTop: -30, // @WORKAROUND
			}}
		>
			<div className="container">
				<div className="narrow-grid">
					<div className="narrow-grid__item item--full-width">
						<Typewriter color={props.color} key={props.text} size="h1" text={props.overrideText ? props.overrideText : props.text} />
						{props.breadcrumb !== false && <Breadcrumbs title={props.overrideText ? props.overrideText : props.text} />}
					</div>
				</div>
			</div>
		</div>
	)
}

export default Header
